'use client';

import Avatar from '@/components/atom/avatar';
import { Button } from '@/components/atom/button';
import MoreMenuIcon from '@/components/ui/icons/more-menu-icon';
import MentionText from '@/components/MentionText';
import useGetCommentsByContentId from '@/hooks/react-query/posts/queries/useGetCommentsByContentId';
import { CommentType } from '@/types';
import { ContentTypeEnum, PostCallEnum } from '@/utils/enums';
import { formatRelativeDate } from '@/utils/format-time';
import React from 'react';
import PostFooter from './PostFooter';
import PostCommentForm from './PostCommentForm';
import CommentThread from './CommentThread';
import useToggleLikeMutation from '@/hooks/react-query/posts/mutations/useToggleLikeMutation';

export default function CommentItem({
  comment,
  isChild,
  postSlug,
  dataKey,
  hideReply = false,
}: {
  comment: CommentType;
  isChild?: boolean;
  hideReply?: boolean;
  postSlug: string;
  dataKey: string;
}) {
  const toggleLikeMutation = useToggleLikeMutation(dataKey);
  const [showReplyInput, setShowReplyInput] = React.useState<number | null>(null);
  const [showComment, setShowComment] = React.useState<number | null>(null);
  const handleFooterItemClick = (v: string) => {
    if (v === 'reply') {
      setShowReplyInput((prev) => {
        return prev ? null : comment.id;
      });
    }
    if (v === 'comment') {
      setShowComment((prev) => (prev ? null : comment.id));
    }
    if (v === 'like') {
      toggleLikeMutation.mutate({
        contentId: comment.id,
        like: !comment.isLiked,
        parentType: ContentTypeEnum.Comment,
      });
    }
  };

  const { data } = useGetCommentsByContentId({
    ContentId: comment.id,
    ParentType: ContentTypeEnum.Comment,
    enabled: !!comment.id,
  });

  return (
    <li key={comment.id} className="mb-10 ml-6">
      <div className="absolute bg-gray-brand3 rounded-full -left-[1.2rem]">
        <div className="relative h-full w-full inset-0 flex items-center justify-center">
          <Avatar
            fallbackText={`${comment?.creator?.firstName ?? ''} ${
              comment?.creator?.lastName ?? ''
            }`}
            className="h-10 w-10"
            src={comment?.creator?.avatarUrl ?? ''}
            isOnline
          />

          {/* <div className="h-2 w-2 rounded-full ring-2 bg-success-500 absolute -bottom-0 -right-1 ring-white"></div> */}
        </div>
      </div>
      <div className="pl-4 w-full">
        <div className="flex items-center justify-between mb-3">
          <div className="gap-3 flex items-center">
            <div className="text-sm font-normal">
              <span className="font-semibold text-gray-brand12 capitalize">
                {comment?.creator?.firstName}&nbsp;
                {comment?.creator?.lastName}
              </span>
            </div>
            <div className="h-1 w-1 min-w-1 bg-gray-brand5 rounded-full"></div>
            <time className="mb-1 text-xs font-normal text-gray-brand5 sm:mb-0">
              {comment?.createdOn ? formatRelativeDate(comment.createdOn) : '-'}
            </time>
          </div>

          {/* TO DO: ADD OPTIONS */}
          {/* <div>
            <Button variant="ghost" size="icon" className="h-fit w-fit p-1 rounded-full">
              <MoreMenuIcon />
            </Button>
          </div> */}
        </div>
        <div className="text-gray-brand7 text-sm mb-4 whitespace-pre-line break-words">
          <MentionText text={comment?.comment} />
        </div>
        <PostFooter
          hideShare
          hideGift
          hideComment={isChild}
          hideView
          hideSave
          hideReply={hideReply}
          likeProcessing={toggleLikeMutation.isPending}
          onClick={handleFooterItemClick}
          data={{
            hasJoinedCommunity: true,
            isLiked: comment?.isLiked,
            likeCount: comment?.likeCount,
            viewCount: comment?.viewCount,
            commentCount: comment?.commentCount,
            slug: postSlug,
          }}
        />
        {showReplyInput === comment.id && (
          <PostCommentForm
            placeholder="Post your reply"
            onSubmit={(e) => console.log(e)}
            id={comment.id}
            parentType={ContentTypeEnum.Comment}
            dataKey={dataKey}
          />
        )}
        {showComment === comment.id && (
          <CommentThread
            comments={data}
            isChild
            postSlug={postSlug}
            dataKey={PostCallEnum.CommentById}
          />
        )}
      </div>
    </li>
  );
}
