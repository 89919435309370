'use client';

import Avatar from '@/components/atom/avatar';
import CustomCarouselDot from '@/components/atom/custom-carousel-dot';
import { PlaySquare } from 'lucide-react';
import React from 'react';
import Carousel from 'react-multi-carousel';
import PostFooter from '../PostFooter';
import CommentSection from '../CommentSection';
import { checkMediaTypeByExtension, cn } from '@/lib/utils';
import { ContentTypeEnum } from '@/utils/enums';
import { PostContentText } from '@/components/PostContentText';
import { formatRelativeDate } from '@/utils/format-time';
import useToggleLikeMutation from '@/hooks/react-query/posts/mutations/useToggleLikeMutation';
import Img from '@/components/atom/img';
import dynamic from 'next/dynamic';
import { Skeleton } from '@/components/atom/skeleton';
import Link from 'next/link';
import { routePaths } from '@/routes/routePaths';

const VideoPlayer = dynamic(() => import('@/components/atom/video-view'), {
  ssr: false,
  loading: () => <Skeleton className="h-20 w-full" />,
});

interface PostProps {
  timeAgo: string;
  hashtags: string[];
  media: string[];
  onClickPlay?: (src: string) => void;
  data?: any;
  dataKey: string;
  className?: string;
}

const Post: React.FC<PostProps> = ({
  timeAgo,
  hashtags,
  media,
  onClickPlay,
  data,
  dataKey,
  className,
}) => {
  const [showComments, setShowComments] = React.useState(false);
  const mutation = useToggleLikeMutation(dataKey);
  const handleFooterIconClick = (v: string) => {
    if (v === 'like') {
      mutation.mutate({
        contentId: data.id,
        like: !data.isLiked,
        parentType: ContentTypeEnum.Post,
      });
    }
    if (v === 'share') {
    }
    if (v === 'comment') {
      setShowComments(!showComments);
    }
  };

  return (
    <div
      className={cn(
        'w-full flex flex-col bg-white p-4 rounded-lg overflow-hidden max-w-[580px]',
        className
      )}
    >
      <div className="w-full h-fit">
        <div className="flex items-center mb-4 gap-2 w-full">
          <Avatar
            fallbackText={data?.communityName}
            src={data?.communityImage}
            className="shadow-img h-12 w-12 min-w-12"
          />
          <div className="flex flex-col gap-0.5 md:gap-1">
            <Link href={`${routePaths.communityDetail}/${data?.communitySlug}`}>
              <p className="font-medium text-black-brand text-sm hover:underline">
                {data?.communityName}
              </p>
            </Link>

            <div className="flex md:hidden flex-col items-start gap-0.5">
              <Link
                href={`${routePaths.people}/${
                  data?.creator?.handle?.startsWith('@')
                    ? data?.creator?.handle.slice(1)
                    : data?.creator?.handle
                }`}
              >
                <p className="text-gray-brand5 text-xs">
                  {data?.creator?.handle?.startsWith('@')
                    ? data?.creator?.handle
                    : `@${data?.creator?.handle}`}{' '}
                </p>
              </Link>

              <p className="text-gray-brand5 text-xs">{formatRelativeDate(new Date(timeAgo))}</p>
            </div>

            <p className="hidden md:flex text-gray-brand5 text-xs items-center gap-0.5 md:gap-2">
              <span className="hidden md:block">Posted by</span>
              <Link
                href={`${routePaths.people}/${
                  data?.creator?.handle?.startsWith('@')
                    ? data?.creator?.handle.slice(1)
                    : data?.creator?.handle
                }`}
                className="hover:underline"
              >
                {data?.creator?.handle?.startsWith('@')
                  ? data?.creator?.handle
                  : `@${data?.creator?.handle}`}{' '}
              </Link>

              <span className="h-1 w-1 rounded-full bg-gray-brand"></span>
              {formatRelativeDate(new Date(timeAgo))}
            </p>
          </div>
        </div>

        <PostContentText text={data?.content || data?.excerpt || ''} />

        <div className="flex flex-wrap mb-4 text-sm w-full">
          {hashtags.map((tag, index) => (
            <span key={index} className="text-blue-500 mr-2">
              #{tag}
            </span>
          ))}
        </div>

        <div className="relative mb-4 w-full">
          {media.length === 1 && checkMediaTypeByExtension(media[0]) === 'image' && (
            // TEMPORARY FIX TO IMAGE WIDTH AND HEIGHT. THE OLD IMPLEMENTATION IS COMMENTED OUT
            <div className="w-full">
              <Img src={media[0]} alt="" className="w-full h-auto" />
            </div>
          )}
          {media.length === 1 && checkMediaTypeByExtension(media[0]) === 'video' && (
            <div className="relative">
              <VideoPlayer
                src={media[0]}
                className="w-full h-[248px] md:h-[352px] rounded-lg"
                controls
              />
              {onClickPlay && (
                <button
                  onClick={() => onClickPlay(media[0])}
                  className="absolute inset-0 flex items-center justify-center text-white text-2xl"
                >
                  <PlaySquare className="text-white" size={50} />
                </button>
              )}
            </div>
          )}
          {media.length > 1 && (
            <div className="relative pb-7">
              <Carousel
                additionalTransfrom={0}
                arrows={false}
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024,
                    },
                    items: 1.5,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 1.5,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 1.5,
                  },
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots
                sliderClass=""
                slidesToSlide={1}
                swipeable
                customDot={<CustomCarouselDot />}
              >
                {media.map((item, index) => (
                  <div
                    key={index}
                    className="relative px-1 h-[248px] md:h-[352px] w-full flex items-center justify-center"
                  >
                    {checkMediaTypeByExtension(item) === 'video' ? (
                      <VideoPlayer src={item} />
                    ) : (
                      <div
                        className="h-full w-full overflow-hidden"
                        style={{
                          backgroundImage: `url("${item}")`,
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                        }}
                      ></div>
                    )}
                    {checkMediaTypeByExtension(item) === 'video' && onClickPlay && (
                      <button
                        onClick={() => onClickPlay(item)}
                        className="absolute inset-0 flex items-center justify-center text-white text-2xl"
                      >
                        <PlaySquare className="text-white" size={50} />
                      </button>
                    )}
                  </div>
                ))}
              </Carousel>
            </div>
          )}
        </div>
      </div>

      <PostFooter
        data={data}
        hideReply
        hideSave
        hideView
        onClick={handleFooterIconClick}
        likeProcessing={mutation.isPending}
        hideGift
      />

      {showComments && (
        <CommentSection
          id={data?.id}
          slug={data?.slug}
          parentType={ContentTypeEnum.Post}
          dataKey={dataKey}
        />
      )}
    </div>
  );
};

export default Post;
