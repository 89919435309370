import { Library } from '@googlemaps/js-api-loader';
import {
  CommunityTypeEnum,
  EventShopTypeEnum,
  GenderEnum,
  IdentityEnum,
  TicketType,
} from './utils/enums';

export const GenderOptions = [
  { label: 'Male', value: GenderEnum.Male },
  { label: 'Female', value: GenderEnum.Female },
  // { label: 'Unknown', value: GenderEnum.Unknown },
];

export const IdentificationTypes = [
  { label: 'NIN', value: IdentityEnum.NIN },
  { label: 'BVN', value: IdentityEnum.BVN },
];
export const EventTypeOptions = [
  { label: 'Physical event', value: EventShopTypeEnum.Physical.toString() },
  { label: 'Virtual event', value: EventShopTypeEnum.Virtual.toString() },
];
export const TicketTypeOptions = [
  { label: 'Paid', value: TicketType.Paid },
  { label: 'Free', value: TicketType.Free },
];
export const CommunityTypeOptions = [
  { label: 'Public', value: CommunityTypeEnum.public },
  { label: 'Private', value: CommunityTypeEnum.private },
];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const onboardingSteps = [
  {
    name: 'Contact info',
    path: '/signup/contact-information',
    step: 1,
  },
  {
    name: 'Bio data',
    path: '/signup/bio-data',
    step: 2,
  },
  {
    name: 'Username',
    path: '/signup/username',
    step: 3,
  },
  {
    name: 'Interests',
    path: '/signup/interests',
    step: 4,
  },
];

export const PRIVATE_NAVBAR_LIST = [
  {
    id: 'blog',
    name: 'Blog',
    path: 'https://blog.fusion.ng',
    external: true,
  },
  //TODO: Add FAQs and Support
  // {
  //   id: 'faqs',
  //   name: 'FAQs',
  //   path: '#',
  //   external: false,
  // },
  // {
  //   id: 'support',
  //   name: 'Support',
  //   path: '#',
  //   external: false,
  // },
  {
    id: 'policies',
    name: 'Policies',
    path: '#',
    external: false,
    children: [
      {
        id: 'privacy-policy',
        name: 'Privacy Policy',
        path: '/privacy-policy',
        external: false,
      },
      {
        id: 'cookies-policy',
        name: 'Cookies Policy',
        path: '/cookies-policy',
        external: false,
      },
      {
        id: 'terms-of-use',
        name: 'Terms of Use',
        path: '/terms-of-use',
        external: false,
      },
    ],
  },
];

export const USER_NAVBAR_LIST = [
  {
    id: 'view-profile',
    name: 'View Profile',
    path: '/profile',
    external: false,
  },
  {
    id: 'referrals',
    name: 'Referrals',
    path: '/referrals',
    external: false,
  },
  //TODO: ANALYTICS
  // {
  //   id: 'analytics',
  //   name: 'Analytics',
  //   path: '/analytics',
  // },
  {
    id: 'settings',
    name: 'Settings',
    path: '/settings',
    external: false,
  },
];

export const passwordValidations = [
  {
    label: 'Minimum of 8 characters',
    test: (pw: string) => pw.length >= 8,
  },
  {
    label: 'At least 1 uppercase letter',
    test: (pw: string) => /[A-Z]/.test(pw),
  },
  {
    label: 'At least 1 lowercase letter',
    test: (pw: string) => /[a-z]/.test(pw),
  },
  {
    label: 'At least 1 number',
    test: (pw: string) => /\d/.test(pw),
  },
  {
    label: 'At least 1 special character',
    test: (pw: string) => /[^A-Za-z0-9]/.test(pw),
  },
];

export const PUBLIC_NAVBAR_LIST = [
  {
    id: 'blog',
    name: 'Blog',
    path: 'https://blog.fusion.ng',
    external: true,
  },
  {
    id: 'explore',
    name: 'Explore',
    path: '/explore/posts',
    external: false,
  },
  {
    id: 'support',
    name: 'Support',
    path: '#',
    external: false,
  },
  {
    id: 'signin',
    name: 'Login',
    path: '/signin',
    external: false,
  },
  {
    id: 'signup',
    name: 'Sign Up',
    path: '/signup',
    external: false,
  },
];

export const BANK_TRANSFER_PAYMENT_PARAMS = {
  status: 'status',
  tx_ref: 'tx_ref',
  transaction_id: 'transaction_id',
  paymentStatusCompleted: 'completed',
  paymentStatusSuccessful: 'successful',
  paymentStatusCancelled: 'cancelled',
  eventId: 'eventId',
};

export const MODAL_PARAMS = {
  ref: 'ref',
  type: 'type',
  signInModal: 'signin',
  create: 'create',
  community: 'community',
  storeItem: 'store-item',
  post: 'post',
  newPost: 'new-post',
  eventTitle: 'edit-event-title',
  eventDetails: 'event-detail',
  editEventId: 'event-id',
  walletActivation: 'wallet-activation',
  createEventCommunityId: 'create-event-community',
};

export const REDIRECT_PARAMS = {
  from: 'from',
  app: 'app',
};

export const PAGE_SEARCH_PARAMS = {
  search: 'search',
  searchQuery: 'search_query',
  tab: 'tab',
};

export const LOCAL_STORAGE_KEYS = {
  shopPath: 'shopPath',
};

// Define the allowed file types
export const SUPPORTED_IMAGE_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

// Define the maximum file size (5MB)
export const FILE_SIZE = 5 * 1024 * 1024;

export const nigerianCountryCode = [{ label: '234', value: '234', country: 'Nigeria' }];

export const NigerianStates = [
  'Abia',
  'Adamawa',
  'Akwa Ibom',
  'Anambra',
  'Bauchi',
  'Bayelsa',
  'Benue',
  'Borno',
  'Cross River',
  'Delta',
  'Ebonyi',
  'Edo',
  'Ekiti',
  'Enugu',
  'FCT - Abuja',
  'Gombe',
  'Imo',
  'Jigawa',
  'Kaduna',
  'Kano',
  'Katsina',
  'Kebbi',
  'Kogi',
  'Kwara',
  'Lagos',
  'Nasarawa',
  'Niger',
  'Ogun',
  'Ondo',
  'Osun',
  'Oyo',
  'Plateau',
  'Rivers',
  'Sokoto',
  'Taraba',
  'Yobe',
  'Zamfara',
];

export const keyboardKeys = {
  enter: 'Enter',
  escape: 'Escape',
};

export const NIGERIA_BOUND = {
  north: 13.892,
  south: 4.277,
  west: 2.676,
  east: 14.678,
};
export const LAGOS_BOUNDS = {
  north: 6.7027,
  south: 6.3939,
  west: 3.0982,
  east: 3.5407,
};

export const LAGOS_CENTER = { lat: 6.5244, lng: 3.3792 };
export const NIGERIA_CENTER = {
  lat: 9.082,
  lng: 8.6753,
};

export const GOOGLE_MAP_LIBRARIES: Library[] = ['core', 'places', 'maps', 'marker', 'geocoding'];
