'use client';

import React from 'react';
import PostCommentForm from './PostCommentForm';
import useGetCommentsByContentId from '@/hooks/react-query/posts/queries/useGetCommentsByContentId';
import InfiniteScrollContainer from '@/components/InfiniteScrollContainer';
import { ParentTypeEnumType } from '@/types';
import CommentThread from './CommentThread';
import { cn } from '@/lib/utils';
import { PostCallEnum } from '@/utils/enums';

export default function CommentSection({
  id,
  slug,
  parentType,
  className,
  threadContainerClassName,
  editorClassName,
  dataKey,
}: {
  id: number;
  slug: string;
  parentType: number;
  className?: string;
  threadContainerClassName?: string;
  editorClassName?: string;
  dataKey: string;
}) {
  const { data, isFetchingNextPage, hasNextPage, isFetching, fetchNextPage } =
    useGetCommentsByContentId({
      ContentId: id,
      ParentType: parentType as ParentTypeEnumType,
      enabled: true,
    });

  return (
    <InfiniteScrollContainer
      loading={isFetchingNextPage}
      onBottomReached={() => hasNextPage && !isFetching && fetchNextPage()}
      className={cn('pl-4 mt-8', className)}
    >
      <div className={cn(editorClassName)}>
        <PostCommentForm
          placeholder="Post your comment"
          id={id}
          parentType={parentType}
          dataKey={dataKey}
        />
      </div>
      <div className={cn(threadContainerClassName)}>
        <CommentThread comments={data} postSlug={slug} dataKey={PostCallEnum.CommentById} />
      </div>
    </InfiniteScrollContainer>
  );
}
